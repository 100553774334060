import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GrShare, GrCopy } from 'react-icons/gr';

import firebase from '../firebase';
import './InventoryBoardManager.css';

export default function InventoryBoardManager() {
  const [boardBlob, setBoardBlob] = useState('');
  const [newBoardName, setNewBoardName] = useState('');
  const [currentUser, setCurrentUser] = useState({
    user: {
      uid: '',
      displayName: '',
    },
  });

  // const user = firebase.auth().currentUser;

  const updateBoardBlob = (userBlob) => {
    const boardBlobRef = firebase
      .database()
      .ref(`Users/${userBlob.uid}/boards`);
    boardBlobRef.on('value', (snapshot) => {
      const boards = snapshot.val();
      const newBoardList = [];
      if (boards !== null) {
        Object.values(boards).forEach((b) => {
          newBoardList.push(b);
        });
        setBoardBlob(newBoardList);
      }
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((autheduser) => {
      setCurrentUser(autheduser);
      updateBoardBlob(autheduser);
    });
    // if (currentUser !== null) {
    // }
  }, []);

  const handleCreateInventoryBoard = (e) => {
    e.preventDefault();
    return false;
  };

  const handleNewBoardNameChange = (e) => {
    setNewBoardName(e.target.value);
  };

  const createInventoryBoard = () => {
    const boardBlobRef = firebase
      .database()
      .ref(`Users/${currentUser.uid}/boards`);
    const newBoardId = `${Math.random().toString(36).substr(2, 16)}`;
    const board = {
      name: newBoardName,
      id: newBoardId,
    };

    boardBlobRef.push(board);

    setNewBoardName('');
  };

  return (
    <div className="inventory-board-manager">
      <h2>Your Inventory Boards</h2>
      <div className="warning">
        These URLs are unique identifiers! Keep them a secret so that strangers
        can't join your game!
      </div>
      <form onSubmit={handleCreateInventoryBoard}>
        <label htmlFor="newInventoryBoard">
          Give your board a name:
          <br />
          <input
            type="text"
            onChange={handleNewBoardNameChange}
            value={newBoardName}
            id="newInventoryItemName"
          />
          <button type="submit" onClick={createInventoryBoard} tabIndex="-1">
            + Create new board
          </button>
        </label>
      </form>
      <div className="inventory-board-list">
        {boardBlob ? (
          <div>
            <h3 className="board-list-header">Board list:</h3>
            <div className="board-list">
              {boardBlob.map((board, index) => (
                <div className="board-single" key={index}>
                  <div className="board-name">
                    <a
                      href={`/magic/${board.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {board.name} <GrShare />
                    </a>
                  </div>
                  <div className="board-uri">
                    <i>Click to copy URL for "{board.name}":</i>
                    <br />
                    <CopyToClipboard
                      text={`https://${window.location.host}/magic/${board.id}`}
                    >
                      <button type="button">
                        https://{window.location.host}/magic/{board.id}
                        <GrCopy className="copy-icon" />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
