import React, { useContext } from 'react';
import InventoryBoardManager from '../components/InventoryBoardManager/InventoryBoardManager';
import { UserContext } from '../providers/UserProvider';
import './Profile.css';

export default function Profile() {
  const user = useContext(UserContext);

  return (
    <div id="profile-page-holder">
      <div>
        <h2>User</h2>
        <ul>
          <li>
            <strong>Name:</strong> {user ? user.displayName : ''}
          </li>
          <li>
            <strong>Email:</strong> {user ? user.email : ''}
          </li>
        </ul>
      </div>
      <InventoryBoardManager />
    </div>
  );
}
