import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebase from './components/firebase';

import Magic from './pages/Magic';
import Profile from './pages/Profile';

const firebaseAppAuth = firebase.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

function App({ user, signOut, signInWithGoogle }) {
  return (
    <Router>
      <div>
        <div className="app-header">
          <span className="app-title">Magic Pockets</span>
          <span className="app-subtitle">
            An inventory management system, for Role-Playing Games
          </span>
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {user ? (
              <li>
                <Link to="/profile">Profile</Link>
              </li>
            ) : (
                ''
              )}
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/magic/:boardId">
            <Magic />
          </Route>
          {user ? (
            <Route path="/profile">
              <Profile />
            </Route>
          ) : (
              ''
            )}

          <Route path="/">
            <div>
              <div className="App">
                <div className="app-body">
                  {user ? (
                    <p>Hello, {user.displayName}</p>
                  ) : (
                      <p>One party member must sign in, in order to use this app.</p>
                    )}
                  {user ? (
                    <button type="button" onClick={signOut}>
                      Sign out
                    </button>
                  ) : (
                      <button type="button" onClick={signInWithGoogle}>
                        Sign in with Google
                      </button>
                    )}
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
