import React from 'react';
import { GrFormTrash } from 'react-icons/gr';

import firebase from '../firebase';
import './InventoryItemControls.css';

export default function InventoryItemControls({
  item,
  windowId,
  windowItemId,
  inventoryViewerId,
}) {
  const deleteInventoryItem = () => {
    const itemRefInWindow = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/windows/${windowId}/items`)
      .child(windowItemId);
    const itemRefInMasterList = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/items/`)
      .child(item.id);

    itemRefInWindow.remove();
    itemRefInMasterList.remove();
  };

  return (
    <span className="item-control-button">
      <span className="item-control-glyph" onClick={deleteInventoryItem}>
        <GrFormTrash className="item-control-icon" /> Delete Item
      </span>
    </span>
  );
}
