import React, { useState, useEffect } from 'react';
import EdiText from 'react-editext';
import './InventoryItem.css';

import firebase from '../firebase';

export default function InventoryItem(props) {
  const [itemMetaDataBlob, setItemMetaDataBlob] = useState('');

  const itemBlobRef = firebase
    .database()
    .ref(`Boards/${props.inventoryViewerId}/items/${props.item.id}`);
  itemBlobRef.on('value', (snapshot) => {
    const item = snapshot.val();
    if (item !== null && item.description !== itemMetaDataBlob.description) {
      setItemMetaDataBlob(item);
    }
  });

  useEffect(() => {
    const itemBlobRef = firebase
      .database()
      .ref(`Boards/${props.inventoryViewerId}/items/${props.item.id}`);
    itemBlobRef.once('value', (snapshot) => {
      const item = snapshot.val();
      setItemMetaDataBlob(item);
    });
  }, [props.item.id]);

  const handleItemNameChange = (newItemName) => {
    firebase
      .database()
      .ref(
        `Boards/${props.inventoryViewerId}/windows/${props.windowId}/items/${props.windowItemId}`,
      )
      .update({
        name: newItemName,
      });
  };

  const handleItemDescriptionChange = (newItemDescription) => {
    firebase
      .database()
      .ref(`Boards/${props.inventoryViewerId}/items/${props.item.id}`)
      .update({
        description: newItemDescription,
      });
  };

  return (
    <div className="inventory-item">
      <div className="inventory-item-title">
        <EdiText
          type="text"
          value={props.item.name}
          onSave={handleItemNameChange}
          submitOnEnter
          cancelOnEscape
          showButtonsOnHover
        />
      </div>
      <div className="inventory-item-description">
        <div className="item-description-label">
          <i>Description:</i>
        </div>
        <EdiText
          type="textarea"
          value={itemMetaDataBlob.description}
          onSave={handleItemDescriptionChange}
          cancelOnEscape
          showButtonsOnHover
        />
      </div>
    </div>
  );
}
