import React, { useState } from 'react';
import './InventoryWindow.css';
import EdiText from 'react-editext';
import firebase from '../firebase';

import InventoryItem from '../InventoryItem/InventoryItem';
import InventoryItemControls from '../InventoryItem/InventoryItemControls';

export default function IventoryWindow({
  window,
  deleteFunction,
  inventoryViewerId,
}) {
  const [newItemName, setNewItemName] = useState('');

  const handleNewItemNameChange = (e) => {
    setNewItemName(e.target.value);
  };

  const createInventoryItem = () => {
    const inventoryWindowRef = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/windows/${window.id}/items`);
    const itemListRef = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/items`);

    const item = {
      description: 'None yet.',
    };

    const newItem = itemListRef.push(item);
    const newInventoryWindowRef = {
      id: newItem.path.pieces_[3],
      name: newItemName,
    };
    inventoryWindowRef.push(newInventoryWindowRef);
    setNewItemName('');
  };

  const handleAddNewWindow = (e) => {
    e.preventDefault();
    return false;
  };

  const handleTitleChange = (newTitle) => {
    firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/windows/${window.id}`)
      .update({
        title: newTitle,
      });
  };

  return (
    <div className="inventory-window">
      <div className="inventory-window-title-bar">
        <span className="title">
          <EdiText
            type="text"
            value={window.title}
            onSave={handleTitleChange}
            submitOnEnter
            cancelOnEscape
            showButtonsOnHover
          />
        </span>
        <span className="window-controls">
          <button
            type="button"
            tabIndex="-1"
            onClick={() => deleteFunction(window.id)}
          >
            X
          </button>
        </span>
      </div>

      <div>
        {window.items
          ? Object.entries(window.items).map((item, index) => (
              <div key={index} className="inventory-item-holder">
                <div className="inventory-item-name-holder">
                  <InventoryItem
                    windowId={window.id}
                    windowItemId={item[0]}
                    item={item[1]}
                    inventoryViewerId={inventoryViewerId}
                    index={index}
                  />
                </div>
                <div className="inventory-item-controls-holder">
                  <InventoryItemControls
                    windowId={window.id}
                    windowItemId={item[0]}
                    inventoryViewerId={inventoryViewerId}
                    item={item[1]}
                  />
                </div>
              </div>
            ))
          : ' '}
      </div>

      <form onSubmit={handleAddNewWindow}>
        <label htmlFor="newInventoryItemName">
          Add item to this list:
          <br />
          <input
            type="text"
            onChange={handleNewItemNameChange}
            value={newItemName}
            id="newInventoryItemName"
          />
          <button type="submit" onClick={createInventoryItem} tabIndex="-1">
            + Add
          </button>
        </label>
      </form>
    </div>
  );
}
