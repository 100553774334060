import React, { useState, useEffect, useContext } from 'react';
import firebase from '../firebase';
import { UserContext } from '../../providers/UserProvider';
import './InventoryWindowViewport.css';

import InventoryWindow from '../InventoryWindow/InventoryWindow';

export default function IventoryWindowViewport({ inventoryViewerId }) {
  const [windowBlob, setWindowBlob] = useState('');
  const [newWindowName, setNewWindowName] = useState('');
  const user = useContext(UserContext);

  useEffect(() => {
    const windowBlobRef = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/windows`);
    windowBlobRef.on('value', (snapshot) => {
      const windows = snapshot.val();
      const newWindowList = [];
      for (const id in windows) {
        newWindowList.push({ id, ...windows[id] });
      }
      setWindowBlob(newWindowList);
    });
  }, []);

  const handleWindowNameChange = (e) => {
    setNewWindowName(e.target.value);
  };

  const createInventoryWindow = () => {
    const windowBlobRef = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/windows`);
    const window = {
      title: newWindowName,
    };

    windowBlobRef.push(window);

    setNewWindowName('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    return false;
  };

  const deleteWindow = (w) => {
    const windowItemsRef = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/windows/${w}/items`);
    windowItemsRef.once('value', (snapshot) => {
      const windowItemsList = snapshot.val();
      if (windowItemsList !== null) {
        Object.values(windowItemsList).forEach((windowItem) => {
          const itemRefInMasterList = firebase
            .database()
            .ref(`Boards/${inventoryViewerId}/items/`)
            .child(windowItem.id);
          itemRefInMasterList.remove();
        });
      }
    });

    const windowRef = firebase
      .database()
      .ref(`Boards/${inventoryViewerId}/windows`)
      .child(w);
    windowRef.remove();
  };

  return (
    <div className="inventory-window-viewport">
      <div className="viewport-header">
        <h2>Board {inventoryViewerId}</h2>

        {user ? (
          <p>
            <strong>Logged in as:</strong> {user ? user.email : ''}
          </p>
        ) : (
          ''
        )}

        <form onSubmit={handleSubmit}>
          <strong>Create new inventory window:</strong>
          <br />
          <label htmlFor="newInventoryWindowName">
            Name of Person, place etc. which holds the items: <br />
            <input
              id="newInventoryWindowName"
              type="text"
              onChange={handleWindowNameChange}
              value={newWindowName}
            />
          </label>
          <button type="submit" onClick={createInventoryWindow}>
            + Add
          </button>
        </form>
      </div>

      {windowBlob
        ? windowBlob.map((window) => (
            <InventoryWindow
              window={window}
              key={window.id}
              deleteFunction={deleteWindow}
              inventoryViewerId={inventoryViewerId}
              className="inventory-window"
            />
          ))
        : ' '}
    </div>
  );
}
